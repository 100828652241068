import {ComponentBase} from "../components/core/ComponentBase";
import React from "react";
import "../../css/components/blocks.scss";
import img1 from "../../static/image/image_partner_1.jpg";
import img2 from "../../static/image/image_partner_2.jpg";
import img3 from "../../static/image/image_partner_3.jpg";
import img4 from "../../static/image/image_partner_4.jpg";
import img5 from "../../static/image/image_partner_5.jpg";
import img6 from "../../static/image/image_partner_6.jpg";
import img7 from "../../static/image/image_partner_7.jpg";

export class FifthBlockPartners extends ComponentBase<{}, {}> {
    render() {
        return (
            <div className="fifth-block flex-col flex-align-center" id="partners">
                <header className="margin-t-l">
                    Партнеры
                </header>
                <div className="margin-t-l margin-b-l content flex-row flex-j-between flex-wrap margin-all-b-l">
                    {PARTNERS.map(partner => <Partner partner={partner} key={partner.name}/>)}
                </div>
            </div>
        )
    }
}

type PartnerType = {
    img: any;
    name: string;
}

type PartnerProps = {
    partner: PartnerType;
}

class Partner extends ComponentBase<PartnerProps> {
    render() {
        const partner = this.props.partner;
        return (
            <div className="partner flex-col width-25 flex-align-center">
                <div className="img-block"><img src={partner.img} alt={partner.name} /></div>
                <header className="margin-t-m text-align-center">{partner.name}</header>
            </div>
        );
    }
}

const PARTNERS: Array<PartnerType> = [
    {
        img: img1,
        name: "Строительная группа \"Развитие\"",
    },
    {
        img: img2,
        name: "Федеральная Налоговая служба Пермского края",
    },
    {
        img: img3,
        name: "Чайковский техникум промышленных технологий и управления",
    },
    {
        img: img4,
        name: "Пенсионный Фонд России",
    },
    {
        img: img5,
        name: "Чайковская детская городская больница",
    },
    {
        img: img6,
        name: "Чайковский медицинский колледж",
    },
    {
        img: img7,
        name: "Федеральное государственное бюджетное водохозяйственное учерждение",
    },
];