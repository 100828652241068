import {ComponentBase} from "../components/core/ComponentBase";
import React from "react";
import "../../css/components/blocks.scss";
import img1 from "../../static/image/image_service_1.jpg";
import img2 from "../../static/image/image_service_2.jpg";
import img3 from "../../static/image/image_service_3.jpg";
import img4 from "../../static/image/image_service_4.jpg";

export class FourthBlockServices extends ComponentBase<{}, {}> {
    render() {
        return (
            <div className="fourth-block flex-col flex-align-center" id="services">
                <header className="margin-t-l">
                    Наши услуги
                </header>
                <div className="margin-t-l margin-b-l content flex-row flex-wrap">
                    {SERVICES.map(service => <Service service={service} key={service.name}/>)}
                </div>
            </div>
        )
    }
}

type ServiceType = {
    img: any;
    name: string;
    description: string;
}

type ServiceProps = {
    service: ServiceType;
}

class Service extends ComponentBase<ServiceProps> {
    render() {
        const service = this.props.service;
        return (
            <div className="service flex-col width-50 flex-align-center">
                <img src={service.img} alt={service.name} />
                <header className="margin-t-m">{service.name}</header>
                <span className="text-align-center width-80 margin-t-m margin-b-l">{service.description}</span>
            </div>
        );
    }
}

const SERVICES: Array<ServiceType> = [
    {
        img: img1,
        name: "Физическая охрана",
        description: "Физическая охрана объектов любой сложности",
    },
    {
        img: img2,
        name: "Пультовая охрана",
        description: "Охранная система, позволяющая круглосуточно получать оперативные сведения относительно целостности объектов. В случае несанкционированного проникновения к Вашему объекту будет направлен экипаж группы быстрого реагирования",
    },
    {
        img: img3,
        name: "Сопровождение грузов",
        description: "Охрана Вашего груза при перемещении по территории",
    },
    {
        img: img4,
        name: "Охрана массовых мероприятий",
        description: "Обеспечение правопорядка при скоплении большого количества людей.",
    },
];