import {ComponentBase} from "../components/core/ComponentBase";
import React from "react";
import "../../css/components/blocks.scss"

export class FirstBlockName extends ComponentBase<{}, {}> {
    render() {
        return (
            <div className="first-block flex-col flex-align-center flex-j-center">
                <div className="name">
                    Группа частных <br/> охранных <br/> организаций "Орион"
                </div>
                <div className="slogan margin-t-xxl">
                    Мы значительно ближе, чем кажется!
                </div>
            </div>
        )
    }
}