import {ComponentBase} from "../components/core/ComponentBase";
import React from "react";
import "../../css/components/blocks.scss"
import img1 from "../../static/image/image_about_1.jpg";
import img2 from "../../static/image/image_about_2.jpg";
import img3 from "../../static/image/image_about_3.jpg";
import img4 from "../../static/image/image_about_4.jpg";

export class SecondBlockAbout extends ComponentBase<{}, {}> {
    render() {
        return (
            <div className="second-block flex-row flex-j-center" id="about">
                <div className="content flex-row margin-b-l">
                    <div className="width-50 text-align-center margin-t-xxl margin-r-l">
                        <header>О нас</header>
                        <p>
                            Группа частных охранных организаций "Орион" предоставляет полный комплекс услуг по обеспечению физической безопасности и технической охране объектов. Силами нашей компании осуществляется охрана объектов различной категории сложности.
                        </p>
                        <p>
                            Наши принципы: законность, конфиденциальность, верность интересам клиента и сохранение его коммерческой тайны, ответственность за взятые на себя обязательства.
                        </p>
                    </div>
                    <div className="flex-row flex-wrap width-50 margin-t-l">
                        <img src={img1} alt="1" className="margin-r-m margin-b-m"/>
                        <img src={img2} alt="2" className="margin-r-m margin-b-m"/>
                        <img src={img3} alt="3" className="margin-r-m margin-b-m"/>
                        <img src={img4} alt="4" className="margin-r-m margin-b-m"/>
                    </div>
                </div>
            </div>
        )
    }
}