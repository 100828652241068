import {ComponentBase} from "../components/core/ComponentBase";
import React from "react";
import "../../css/components/blocks.scss"
import checkmark from "../../static/checkmark.svg";

export class ThirdBlockPluses extends ComponentBase<{}, {}> {
    render() {
        return (
            <div className="third-block flex-col flex-align-center margin-t-l" id="pluses">
                <header>
                    Преимущества компании
                </header>
                <p>
                    Группа частных охранных организаций "ОРИОН" это:
                </p>
                <div className="margin-t-l margin-b-l">
                    {PLUSES.map(plus => <Plus plus={plus} key={plus.name}/>)}
                </div>
            </div>
        )
    }
}

type PlusType = {
    name: string;
}

type PlusProps = {
    plus: PlusType;
}

class Plus extends ComponentBase<PlusProps>{
    render() {
        return (
            <div className="plus flex-row flex-align-center margin-b-l">
                <img src={checkmark} alt="checkmark" className="margin-r-m"/>
                <header>{this.props.plus.name}</header>
            </div>
        );
    }
}

const PLUSES: Array<PlusType> = [
    {
        name: "Профессиональный штат сотрудников",
    },
    {
        name: "Доступные цены",
    },
    {
        name: "Индивидуальный подход к нуждам клиентов",
    },
];