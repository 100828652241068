import React, {CSSProperties} from "react";

type ComponentProps = {
    className?: string;
    style?: CSSProperties;
    children?: React.ReactNode | Array<React.ReactNode> | null | undefined;
};

export abstract class ComponentBase<P = {}, S = {}, StateFinal = S> extends React.Component<P & ComponentProps, StateFinal> {
    abstract render(): React.ReactNode;
}