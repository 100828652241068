import {ComponentBase} from "../components/core/ComponentBase";
import "../../css/components/App.scss"
import React from "react";

export class Footer extends ComponentBase<{}> {
    render() {
        return (
            <div className="footer flex-col flex-align-center" id="footer">
                <span className="margin-t-l margin-b-m">НАШИ КОНТАКТЫ</span>
                <div className="flex-row flex-j-around width-100">
                    <header className="text-align-center">
                        Дежурная часть: <br/>
                        8 (342) 229-12-99 <br/>
                        8 (950) 441 11 02 <br/>
                        8 (342) 276 76 56
                    </header>
                    <header className="text-align-center margin-t-l margin-b-m">
                        Коммерческий отдел: <br/>
                        8 (912) 985-31-17 <br/>
                        Orion.security@mail.ru
                    </header>
                </div>
                <p>614000, г. Пермь, улица Подлесная, 37а, офис 2</p>
            </div>
        );
    }
}