import {ComponentBase} from "../components/core/ComponentBase";
import "../../css/components/App.scss"
import logo from "../../static/logo.jpg";
import React from "react";

type State = {};

export class Header extends ComponentBase<{}, State> {
    render() {
        return (
            <div className="header flex-row flex-j-center">
                <div className="content flex-row flex-j-between flex-align-center">
                    <img src={logo} alt="logo"/>
                    <div className="flex-row flex-j-between width-50">
                        <a href="#about" className="link-block">О нас</a>
                        <a href="#pluses" className="link-block">Преимущества</a>
                        <a href="#services" className="link-block">Услуги</a>
                        <a href="#partners" className="link-block">Партнеры</a>
                        <a href="#footer" className="link-block">Контакты</a>
                    </div>
                </div>
            </div>
        );
    }
}