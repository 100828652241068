import React from 'react';
import '../css/components/App.scss';
import {Header} from "./blocks/Header";
import {FirstBlockName} from "./blocks/FirstBlockName";
import {SecondBlockAbout} from "./blocks/SecondBlockAbout";
import {ThirdBlockPluses} from "./blocks/ThirdBlockPluses";
import {FourthBlockServices} from "./blocks/FourthBlockServices";
import {FifthBlockPartners} from "./blocks/FifthBlockPartners";
import {Footer} from "./blocks/Footer";

function App() {
  return (
    <div className="App">
      <Header/>
      <FirstBlockName/>
      <SecondBlockAbout/>
      <ThirdBlockPluses/>
      <FourthBlockServices/>
      <FifthBlockPartners/>
      <Footer/>
    </div>
  );
}

export default App;
